<template>
  <div class="content">
    <div class="info-box">
      <img class="info-avatar" :src="userInfo.avatar" alt />
      <div class="info-name">{{userInfo.name}}</div>
      <div class="info-date">{{userInfo.date}}</div>
    </div>
    <div class="menu-item mb20" @click="menuClick('myScore')">
      <img src="../../assets/img/mine/icon-myScore.png" class="menu-icon" alt />
      <div class="menu-title">我的积分</div>
      <img src="../../assets/img/mine/icon-arrow.png" class="menu-arrow" alt />
    </div>
    <div class="menu-box">
      <template v-for="(item, index) in menuList">
        <div
          class="menu-item"
          :key="index"
          @click="menuClick(item.path)"
          v-if="item.isAdmin === userInfo.isAdmin || item.isSupport === userInfo.isSupport"
        >
          <img :src="item.icon" class="menu-icon" alt />
          <div class="menu-title">{{item.title}}</div>
          <img src="../../assets/img/mine/icon-arrow.png" class="menu-arrow" alt />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'mine',
  data() {
    return {
      userInfo: {
        avatar: '',
        name: '',
        date: '',
        isAdmin: false,
        isSupport: false
      },
      menuList: [
        {
          icon: require('../../assets/img/mine/icon-pairing.png'),
          title: '结对党员积分',
          path: 'pairingScore',
          isSupport: true
        },
        {
          icon: require('../../assets/img/mine/icon-task.png'),
          title: '任务总结审批',
          path: 'taskApproveList',
          isAdmin: true
        },
        {
          icon: require('../../assets/img/mine/icon-microWish.png'),
          title: '群众微心愿审核',
          path: 'toCheckList',
          isAdmin: true
        },
        {
          icon: require('../../assets/img/mine/icon-feedback.png'),
          title: '情况反馈处理',
          path: 'situationFeedbackList',
          isAdmin: true
        },
        {
          icon: require('../../assets/img/mine/icon-life.png'),
          title: '生活咨询回复',
          path: 'lifeConsultationAuditList',
          isAdmin: true
        }
      ]
    };
  },
  methods: {
    menuClick(path) {
      //跳转对应页面
      this.$router.push({ name: path });
    }
  },
  created() {
    const roleName = localStorage.getItem('roleName');
    const avatar = localStorage.getItem('Avatar');
    const nickName = localStorage.getItem('nickName');
    const userInfo = localStorage.getItem('user');

    if (roleName && roleName.indexOf('dmin') !== -1) {
      this.userInfo.isAdmin = true;
    }
    if (userInfo) {
      this.userInfo.date = JSON.parse(userInfo).joinPartyDate.split(' ')[0];
      this.userInfo.isSupport =
        JSON.parse(userInfo).supportId !== '0' && JSON.parse(userInfo).supportId
          ? true
          : false;
    }
    this.userInfo.avatar = avatar;
    this.userInfo.name = nickName;
  }
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content {
  height: 100%;
  background-color: #f5f5f5;
  .info-box {
    height: 380 * $px;
    background: url(../../assets/img/mine/avatar-bg.png);
    background-size: 100% 100%;
    overflow: hidden;
    .info-avatar {
      display: block;
      margin: 74 * $px auto 27 * $px;
      width: 140 * $px;
      height: 140 * $px;
      border: 4 * $px solid rgba(238, 238, 238, 1);
      border-radius: 50%;
    }
    .info-name {
      font-size: 32 * $px;
      text-align: center;
      color: #fff;
    }
    .info-date {
      color: #ffcfcf;
      font-size: 24 * $px;
      text-align: center;
      padding-top: 15 * $px;
    }
  }
  .menu-item {
    height: 100 * $px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin-bottom: 1px;
    .menu-icon {
      display: block;
      width: 38 * $px;
      height: 38 * $px;
      margin: 0 12 * $px 0 20 * $px;
    }
    .menu-title {
      flex: 1;
      font-size: 28 * $px;
      color: #222;
    }
    .menu-arrow {
      display: block;
      width: 17 * $px;
      height: 30 * $px;
      margin-right: 30 * $px;
    }
  }
  .mb20 {
    margin-bottom: 20 * $px;
  }
}
</style>